<template>
    <div class="ex_panel_tfair">
        <b-breadcrumb class="custom-bread">
            <b-breadcrumb-item href="#">Tread Fair Panel</b-breadcrumb-item>
            <b-breadcrumb-item active>Dashboard</b-breadcrumb-item>
        </b-breadcrumb>
        <section>
            <b-overlay :show="loading">
                <b-row>
                    <b-col sm="12">
                        <h4 class="text-center mb-4" style="border-bottom: 1px solid #ddd;"> {{ currentLocale === 'bn' ? 'ঢাকা আন্তর্জাতিক বাণিজ্য মেলা' : 'Dhaka International Trade Fair' }} </h4>
                    </b-col>
                    <b-col sm="3">
                        <b-form-group
                            label-for="year"
                        >
                        <template v-slot:label>
                            {{$t('globalTrans.year')}}
                        </template>
                        <b-form-select
                            plain
                            v-model="search.year"
                            :options="yearList"
                            id="year"
                        >
                            <template v-slot:first>
                            <b-form-select-option disabled :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="3">
                        <b-form-group
                            label-for="fair_id"
                        >
                        <template v-slot:label>
                            {{ $t('ditfConfig.fair_name') }}
                        </template>
                        <b-form-select
                            plain
                            v-model="search.fair_id"
                            :options="fairTitleList"
                            id="fair_id"
                        >
                            <template v-slot:first>
                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="3">
                        <b-form-group
                            label-for="circular_type"
                        >
                        <template v-slot:label>
                            {{$t('ditfConfig.circular') + ' ' + $t('ditfConfig.type')}}
                        </template>
                        <b-form-select
                            plain
                            v-model="search.circular_type"
                            :options="circularTypeList"
                            id="circular_type"
                        >
                            <template v-slot:first>
                            <b-form-select-option disabled :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="3">
                        <b-form-group
                            label-for="stall_cat_id"
                        >
                        <template v-slot:label>
                            {{$t('externalTradeFair.stall') + ' ' + $t('ditfConfig.category')}}
                        </template>
                        <b-form-select
                            plain
                            v-model="search.stall_cat_id"
                            :options="stallCategoryList"
                            id="stall_cat_id"
                        >
                            <template v-slot:first>
                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="3">
                        <b-button size="sm" variant="primary" class="mb-2" @click="searchData">
                            <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                        </b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-card :title="currentLocale === 'bn' ? 'ঢাকা আন্তর্জাতিক বাণিজ্য মেলা' : 'Dhaka International Trade Fair'">
                            <div class="ditf_participant mb-4">
                                <b-row>
                                    <b-col class="bdr" sm="4">
                                        <div class="info-card applicant">
                                            <div class="icon"><i class="ri-team-fill"></i></div>
                                            <div class="content">
                                                <p>{{ $t('externalTradeFair.total_stall') }}</p>
                                                <h3>{{ $n(stallInfo.total_stall) }}</h3>
                                            </div>
                                        </div>
                                    </b-col>
                                    <b-col class="bdr mt-3 mt-sm-0" sm="4">
                                        <div class="info-card approved">
                                            <div class="icon"><i class="ri-checkbox-fill"></i></div>
                                            <div class="content">
                                                <p>{{ $t('externalTradeFair.approved_stall') }}</p>
                                                <h3>{{ $n(stallInfo.approved_stall) }}</h3>
                                            </div>
                                        </div>
                                    </b-col>
                                    <!-- <b-col class="bdr" sm="4">
                                        <div class="info-card peinding">
                                            <div class="icon"><i class="ri-time-fill"></i></div>
                                            <div class="content">
                                                <p>{{ $t('externalTradeFair.pending_stall') }}</p>
                                                <h3>{{ $n(stallInfo.panding_stall) }}</h3>
                                            </div>
                                        </div>
                                    </b-col> -->
                                    <b-col class="bdr" sm="4">
                                        <div class="info-card paid">
                                            <div class="icon"><i class="ri-user-follow-fill"></i></div>
                                            <div class="content">
                                                <p>{{ $t('externalTradeFair.paid_stall') }}</p>
                                                <h3>{{ $n(stallInfo.paid_stall) }}</h3>
                                            </div>
                                        </div>
                                    </b-col>
                                    <b-col class="bdr mt-3" sm="4">
                                        <div class="info-card unpaid">
                                            <div class="icon"><i class="ri-user-unfollow-fill"></i></div>
                                            <div class="content">
                                                <p>{{ $t('externalTradeFair.unpaid_stall') }}</p>
                                                <h3>{{ $n(stallInfo.unpaid_stall) }}</h3>
                                            </div>
                                        </div>
                                    </b-col>
                                    <b-col class="bdr mt-3" sm="4">
                                        <div class="info-card reject">
                                            <div class="icon"><i class="ri-close-circle-fill"></i></div>
                                            <div class="content">
                                                <p>{{ $t('externalTradeFair.reject_refund_stall') }}</p>
                                                <h3>{{ $n(stallInfo.is_refund) }}</h3>
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
            </b-overlay>
        </section>
    </div>
</template>

<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { DitfStallReport } from '../../api/routes'
export default {
    data () {
        return {
            sortBy: 'division',
            sortDesc: false,
            fairTitleList: [],
            stallCategoryList: [],
            stallInfo: {
                total_stall: 0,
                approved_stall: 0,
                is_refund: 0,
                panding_stall: 0,
                paid_stall: 0,
                unpaid_stall: 0
            },
            loading: false,
            search: {
                year: 0,
                fair_id: 0,
                circular_type: 0,
                stall_cat_id: 0
            }
        }
    },
    created () {
        const currentFiscalYear = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.$store.state.currentFiscalYearId)
        this.search.year = new Date(currentFiscalYear.end_date).getFullYear()
        this.search.circular_type = 1
        this.loadData()
    },
    watch: {
        'search.year': function (newVal) {
            this.fairTitleList = this.dhakaFair()
        },
        'search.circular_type': function (newVal) {
            this.stallCategoryList = this.getStallCategoryList()
        },
        currentLocale: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fairTitleList = this.dhakaFair()
                this.stallCategoryList = this.getStallCategoryList()
            }
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        yearList () {
            return this.$store.state.commonObj.yearList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item, text: this.$n(item, { useGrouping: false }) }
                } else {
                    return { value: item, text: item }
                }
            })
        },
        circularTypeList () {
            return this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.map(item => {
                return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
            })
        }
    },
    methods: {
        searchData () {
            this.loadData()
        },
        dhakaFair () {
            this.search.fair_id = 0
            return this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.dhakaFairList.filter(item => item.year === this.search.year).map(item => {
                if (item !== '') {
                    this.search.fair_id = item.value
                }
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        getStallCategoryList () {
            return this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.filter(item => item.circular_type === this.search.circular_type).map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        async loadData () {
            this.loading = true
            const params = Object.assign({}, this.search)
            const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, DitfStallReport, params)
            if (result.success) {
                this.stallInfo = result.data
                this.loading = false
            }
        }
    }
}
</script>

<style>
.ex_panel_tfair thead.thead-custom {
    color: #fff;
    background: #77a0cb;
}
.ex_panel_tfair .active-circular{
    color: #2943d6;
}
.ex_panel_tfair .table-wrapper{
    margin-bottom: 2rem;
}
.ex_panel_tfair .upcoming-fair {
    background: url(../../../../../../assets/images/fair-card-bg.jpg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover !important;
    position: relative;
}

.ex_panel_tfair .upcoming-fair .icon img {
    width: 100px;
}

.ex_panel_tfair .upcoming-fair .content {
    padding: 2rem;
    width: 100%;
    color: #fff;
    text-align: center;
}
.ex_panel_tfair .ditf_participant .info-card {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.ex_panel_tfair .ditf_participant .info-card .content {
    margin-left: .8rem;
}
.ex_panel_tfair .ditf_participant .info-card .icon {
    padding-right: 1rem;
    color: #fff;
    font-size: 1.5rem;
    margin-right: .6rem;
}

.ex_panel_tfair .ditf_participant .info-card.applicant {
    background: linear-gradient(90deg, #520979 20%, #f9efff 23%);
}

.ex_panel_tfair .ditf_participant .info-card.approved {
    background: rgb(33, 194, 40);
    background: linear-gradient(90deg, #21c228 20%, #effff0 23%);
}

.ex_panel_tfair .ditf_participant .info-card.peinding {
    background: #C96BE5;
    background: linear-gradient(90deg, #C96BE5 20%, #faebff 23%);
}

.ex_panel_tfair .ditf_participant .info-card.reject {
    background: #F8576A;
    background: linear-gradient(90deg, #F8576A 20%, #ffeef0 23%);
}

.ex_panel_tfair .ditf_participant .info-card.paid {
    background: #6349F8;
    background: linear-gradient(90deg, #6349F8 20%, #efecff 23%);
}

.ex_panel_tfair .ditf_participant .info-card.unpaid {
    background: #E79B55;
    background: linear-gradient(90deg, #E79B55 20%, #fff7ef 23%);
}

.ex_panel_tfair .ditf_participant .info-card.atended {
    background: #55A6F8;
    background: linear-gradient(90deg, #55A6F8 20%, #eaf5ff 23%);
}

.ex_panel_tfair .ditf_participant .info-card.foreign {
    background: #2943d6;
    background: linear-gradient(90deg, #2943d6 20%, #e9ecff 23%);
}
</style>
